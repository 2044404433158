import { TIMEZONE } from '@autobid/ui/constants/TIMEZONE'

export function isSummerTime(date: Date): boolean {
  const year = date.getUTCFullYear()

  const march31 = new Date(Date.UTC(year, 2, 31, 1, 0, 0))
  const cestStartUTC = new Date(
    march31.getTime() - march31.getUTCDay() * 24 * 60 * 60 * 1000
  ).getTime()

  const october31 = new Date(Date.UTC(year, 9, 31, 1, 0, 0))
  const cestEndUTC = new Date(
    october31.getTime() - october31.getUTCDay() * 24 * 60 * 60 * 1000
  ).getTime()

  const dateUTC = date.getTime()

  return dateUTC >= cestStartUTC && dateUTC < cestEndUTC
}

export function formatStartDate(
  dateIso: string,
  { showFullDate = false, $t = null } = {}
): string {
  const date = new Date(dateIso)
  const fullDateOptions = showFullDate
    ? { day: '2-digit', month: '2-digit', year: 'numeric' }
    : {}

  const options = {
    ...fullDateOptions,
    hour: '2-digit',
    minute: '2-digit',
    timeZone: TIMEZONE
  } as Parameters<typeof date.toLocaleDateString>[1]

  const formattedDate = date[
    showFullDate ? 'toLocaleDateString' : 'toLocaleTimeString'
  ]('en-GB', options).replace(/\//g, '.') // "en-GB" just to keep design date format, here formatDate is not relevant

  return `${formattedDate} ${$t?.('auction-list.o-clock')} (${
    isSummerTime(date) ? 'CEST' : 'CET'
  })`
}
